/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";
import configData from "../../config.json";
// import validator from "validator";

// const CurrentDate = new Date();
const tmpDate = new Date();
const CurrentMonth = tmpDate.getMonth() + 1;
const CurrentYear = tmpDate.getFullYear();
const CurrentDay = tmpDate.getDate();

const CurrentDate = `${CurrentYear}-${CurrentMonth}-${CurrentDay < 10 ? "0" : ""}${CurrentDay}`;

const YearMinus = CurrentYear - 1;
const YearMinus2 = CurrentYear - 2;

// const Current_thai_date = CurrentDate.toLocaleDateString("th-TH", {
//   year: "numeric",
//   month: "long",
//   day: "numeric",
//   weekday: "long",
// });

const CurrentFiascl = CurrentMonth > 9 ? CurrentYear + 544 : CurrentYear + 543;

const StartCurrentDate =
  CurrentMonth > 9
    ? `${CurrentYear}-10-01`
    : CurrentMonth > 3
      ? `${CurrentYear}-04-01`
      : `${YearMinus}-10-01`;

const previousStartDate =
  CurrentMonth > 9
    ? `${CurrentYear}-04-01`
    : CurrentMonth > 3
      ? `${YearMinus}-10-01`
      : `${YearMinus}-04-01`;
const previousEndDate =
  CurrentMonth > 9
    ? `${CurrentYear}-09-30`
    : CurrentMonth > 3
      ? `${CurrentYear}-03-31`
      : `${YearMinus}-09-30`;

const LastFiscalStartDate =
  CurrentMonth > 9 ? `${YearMinus}-10-01` : `${YearMinus2}-10-01`;
const LastFiscalEndDate =
  CurrentMonth > 9 ? `${CurrentYear}-09-30` : `${YearMinus}-09-30`;

function SectionCounter() {

  const [chartData, setChartdata] = useState([]);
  const [toDayData, setToDayData] = useState([]);
  const [selectBtt, setSelectBtt] = useState(1);
  const [startDateLabal, setStartDateLabel] = useState(() => {
    return new Date(StartCurrentDate).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  });
  const [endDateLabal, setEndDateLabel] = useState(() => {
    return new Date(CurrentDate).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  });

  const [graphLabel, setGraphLabel] = useState("รอบการปฏิบัติงานปัจจุบัน");

  const tSelect = (e) => {
    if (e === 1) {
      getChat(StartCurrentDate, CurrentDate);
      ReadCounter(StartCurrentDate, CurrentDate);
      setGraphLabel("รอบการปฏิบัติงานปัจจุบัน");
      // console.log(StartCurrentDate, CurrentDate);
    } else if (e === 2) {
      getChat(previousStartDate, previousEndDate);
      ReadCounter(previousStartDate, previousEndDate);
      setGraphLabel("รอบการปฏิบัติงานที่ผ่านมา");
      //  console.log(previousStartDate, previousEndDate);
    } else {
      getChat(LastFiscalStartDate, LastFiscalEndDate);
      ReadCounter(LastFiscalStartDate, LastFiscalEndDate);
      setGraphLabel(`ปีงบประมาณที่ผ่านมา (${CurrentFiascl - 1})`);
      // console.log(LastFiscalStartDate, LastFiscalEndDate);
    }
    setSelectBtt(e);
    // alert(StartDateTxt);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: true,
        text: ["กราฟแสดงสถิติการใช้งานเว็บไซต์กองแผนงาน ", graphLabel],
      },
    },
  };
  const optionsD = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: true,
        text: `วันนี้มีผู้เข้าชมเว็บไซต์กองแผนงานจำนวน ${toDayData.totalhit} ครั้ง`,
      },
    },
  };
  let dk = (toDayData.desktophit / toDayData.totalhit) * 100;
  let mb = (toDayData.mobilehit / toDayData.totalhit) * 100;
  const dataD = {
    labels: [
      `คอมพิวเตอร์ตั้งโต๊ะ (${dk.toFixed(2)}%)`,
      `อุปกรณ์เคลื่อนที่ (${mb.toFixed(2)}%)`,
    ],
    datasets: [
      {
        data: [toDayData.desktophit, toDayData.mobilehit],
        backgroundColor: ["rgb(0,255, 255,1)", "rgb(53, 162, 235,1)"],
        borderWidth: 0,
      },
    ],
  };

  const data = {
    labels: chartData.month_txt,
    datasets: [
      {
        label: "จำนวนรวม",
        data: chartData.totalhit,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "อุปกรณ์เคลื่อนที่",
        data: chartData.mobilehit,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgb(53, 162, 235)",
      },
      {
        label: "คอมพิวเตอร์ตั้งโต๊ะ",
        data: chartData.desktophit,
        borderColor: "rgb(0,255, 255)",
        backgroundColor: "rgb(0,255, 255)",
      },
      {
        label: "ไม่สามารถระบุได้",
        data: chartData.unknowhit,
        borderColor: "rgb(53,05, 235)",
        backgroundColor: "rgb(53,05, 235)",
      },
    ],
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const [ip, setIP] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [webCounter, setWebCounter] = useState(0);

  let dollarUSLocale = Intl.NumberFormat("en-US");

  const ReadCounter = (startD, endD) => {
    // // Set Text สำหรับแสดงหน้าเว็บ
    // console.log(startD,endD)
    setStartDateLabel(() => {
      return new Date(startD).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
    });
    setEndDateLabel(() => {
      return new Date(endD).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
    });

    // if(!validator.isDate(StartDateTxt)) alert(StartDateTxt);

    axios
      .get(configData.API_URL + "/w3_api/api/counter/counter_read.php", {
        params: {
          startdate: startD,
          enddate: endD,
        },
      })
      .then((Response) => {
        setWebCounter(Response.data.counter);
      });
  };

  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getChat = (st, nd) => {
    axios
      .get(configData.API_URL + "/w3_api/api/counter/counter_graph_new.php", {
        params: {
          startdate: st,
          enddate: nd,
        },
      })
      .then((Response) => {
        setChartdata(Response.data.chartdata);
      });
  };

  useEffect(() => {
    // alert(StartCurrentDate);
    axios
      .get("https://ipapi.co/json/")
      .then((Response) => {
        setIP(Response.data.ip);
        setCountry(Response.data.country_name);
        setCity(Response.data.city);

        axios
          .get(configData.API_URL + "/w3_api/api/counter/counter.php", {
            params: {
              ip: Response.data.ip,
              country: Response.data.country_name,
              city: Response.data.city,
              device: isMobile,
              startdate: StartCurrentDate,
              enddate: CurrentDate,
            },
          })
          .then((Response) => {
            //  console.log(Response);
            setWebCounter(Response.data.counter);
          });

      })
      .then((e) => {
        axios
          .get(configData.API_URL + "/w3_api/api/counter/counter_graph_new.php", {
            params: {
              startdate: StartCurrentDate,
              enddate: CurrentDate,
            },
          })
          .then((Response) => {
            setChartdata(Response.data.chartdata);
            // console.log(Response.data.chartdata);
          });
      })
      .then((e) => {
        axios
          .get(configData.API_URL + "/w3_api/api/counter/counter_today.php")
          .then((Response) => {
            setToDayData(Response.data.chartdata);
          });
      }
      )

  }, []);

  return (
    <>
      <Container>
        {/* <br /> */}

        <Row>
          {/* <Col md="6">
              <Line options={options} data={data} />
            </Col> */}
          <Col md="12">
            <div className="title">
              <h3>สถิติผู้เยี่ยมชม</h3>
            </div>
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav id="tabs" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      กราฟแสดงสถิติการใช้งานเว็บไซต์กองแผนงาน
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      สถิติย้อนหลัง
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      รายละเอียดอุปกรณ์ของคุณ
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <TabContent activeTab={activeTab} className="text-center">
              <TabPane tabId="1">
                {/* แสดงกราฟ */}
                <Row>
                  <Col md="8">
                    <Line options={options} data={data} />
                  </Col>

                  <Col md="4">
                    <Doughnut options={optionsD} data={dataD} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="mt-5">
                      <p className="mt-5">
                        สถิติการเยี่ยมชมเว็บไซต์ <br></br>
                      </p>
                    </p>
                    <h5>
                      <label className="label label-primary">
                        {webCounter !== 0
                          ? [dollarUSLocale.format(webCounter), " ครั้ง"]
                          : "กำลังโหลดข้อมูล"}
                      </label>
                    </h5>
                    <>
                      <ButtonGroup size="sm">
                        <Button
                          color={selectBtt === 1 ? "primary" : "secondary"}
                          onClick={() => tSelect(1)}
                        >
                          รอบการปฏิบัติงานปัจจุบัน
                        </Button>
                        <Button
                          color={selectBtt === 2 ? "primary" : "secondary"}
                          onClick={() => tSelect(2)}
                        >
                          รอบการปฏิบัติงานที่ผ่านมา
                        </Button>
                        <Button
                          color={selectBtt === 3 ? "primary" : "secondary"}
                          onClick={() => tSelect(3)}
                        >
                          ปีงบประมาณที่ผ่านมา ({CurrentFiascl - 1})
                        </Button>
                      </ButtonGroup>
                    </>
                    <p className="mt-1">
                      <span className="text-primary">เริ่มตั้งแต่</span>{" "}
                      {startDateLabal}
                      <span className="text-primary"> ถึง </span>
                      {endDateLabal}
                      {""}
                    </p>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <p> หมายเลขไอพีของคุณคือ {ip} </p>
                <p>
                  {" "}
                  อุปกรณ์ของคุณ{" "}
                  {isMobile
                    ? "อุปกรณ์เคลื่อนที่ (Mobile Device)"
                    : "คอมพิวเตอร์ตั้งโต๊ะ (Desktop Device)"}{" "}
                </p>
                <p>
                  {city} {country}
                </p>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SectionCounter;
